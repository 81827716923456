<script setup>
import { ref } from 'vue';
import {
    EyeIcon,
    EyeSlashIcon
} from '@heroicons/vue/24/solid';
import { loginWithFirebase, getCustomerDetails, saveCustomerSession, generateFingerPrint } from "../rules";

const props = defineProps({
    completeLoginFlow: Function,
})
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordPattern = /"^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"/;
const isLoading = ref(false);
const showPass = ref(false);
const username = ref('');
const password = ref('');
const formErrors = ref({
    credentials: '',
    username: '',
    password: ''
});
const apiError = ref('');

const toggleShowPass = () => {
    showPass.value = !showPass.value;
};

const extractCustomerDetails = (customerDetailsRes) => {
    const customerDetails = customerDetailsRes?.data?.data?.customerContact;
    const { givenName, familyName } = customerDetails?.givenAndFamilyName;
    const { telephoneNumber } = customerDetails?.telephoneContact[0];
    const { emailAddress } = customerDetails?.emailContact[0];
    const { accountNumber, billingUSI } = customerDetails?.linkedAccountNumbers[0];
    // TODO: Add Billling USI
    return {
        email: emailAddress,
        firstName: givenName,
        lastName: familyName,
        phone: telephoneNumber
    };
};

const validateForm = async () => {
    isLoading.value = true;
    if (username.value.length === 0) {
        formErrors.value.username = 'Please enter your email.';
        isLoading.value = false;
        return;
    } else if (username.value.length !== 0 && !emailPattern.test(username.value)) {
        formErrors.value.username = 'Please enter a valid email.';
        isLoading.value = false;
        return;
    }

    if (password.value.length === 0) {
        formErrors.value.password = 'Please enter your password.';
        isLoading.value = false;
        return;
    }
    // else if (password.value.length !== 0 && !passwordPattern.test(password.value)) {
    //     formErrors.value.password = 'Please enter the correct password'
    //     isLoading.value = false;
    //     return;
    // }
    // Reset input errors
    formErrors.value.username = '';
    formErrors.value.password = '';
    // Call login API
    const response = await loginWithFirebase(username.value, password.value);
    console.log(response);
    if (!response.success) {
        isLoading.value = false;
        apiError.value = response.error || 'Error logging in';
        return;
    } else {
        console.log('Login Success');
        console.log(response.data);
        if (response.data.error) {
            formErrors.value.credentials = "Please enter valid credentials"
            console.log(formErrors.value);
            isLoading.value = false;
            return;
        } else {
            formErrors.value.credentials = ""
        }
        const { idToken, refreshToken } = response.data;
        const customerDetailsRes = await getCustomerDetails(username.value, idToken);
        if (!customerDetailsRes.success) {
            isLoading.value = false;
            apiError.value = customerDetailsRes.error || 'Error getting customer details';
            return;
        } else {
            console.log('Customer Details Success');
            const customerDetails = extractCustomerDetails(customerDetailsRes)
            const fingerPrint = await generateFingerPrint();
            const customerSession = {
                ...customerDetails,
                uuidf: fingerPrint,
                uuidt: idToken,
                uuidr: refreshToken
            };
            const saveSessionRes = await saveCustomerSession(customerSession);
            if (!saveSessionRes.success) {
                isLoading.value = false;
                apiError.value = saveSessionRes.error || 'Error saving customer session';
                return;
            } else {
                console.log('Session Saved');
                isLoading.value = false;
                props.completeLoginFlow();
            }
        }
    }
};

</script>

<template>
    <div class="zf-flex zf-h-full zf-flex-col zf-justify-center zf-items-center">
        <img src="../assets/ziplyLogo.png" alt="Ziply Logo" class="zf-mb-4">
        <h1 class="!zf-text-xl">Please Login</h1>
        <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="formErrors.credentials !== ''">
            {{ formErrors.credentials }}
        </p>
        <form method="post" @submit.prevent="validateForm()" class="zf-flex zf-flex-col zf-w-full zf-p-5 ">
            <div>
                <div class="zf-flex zf-flex-col">
                    <input type="email" name="email" placeholder="Email" v-model.trim="username"
                        @keydown="formErrors.username = ''"
                        class="!zf-w-full !zf-bg-gray-100 !zf-px-4 !zf-py-2 !zf-mb-4 !zf-h-12 zf-border-2 zf-outline-none zf-rounded-lg" />
                </div>
                <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="formErrors.username !== ''">
                    {{ formErrors.username }}
                </p>
                <div class="zf-flex zf-flex-col zf-relative">
                    <input :type="showPass ? 'text' : 'password'" name="password" placeholder="Password"
                        v-model.trim="password" @keydown="formErrors.username = ''"
                        class="!zf-w-full !zf-bg-gray-100 !zf-px-4 !zf-py-2 !zf-mb-4 !zf-h-12 zf-border-2 zf-outline-none zf-rounded-lg" />
                    <EyeIcon class="!zf-w-6 !zf-h-6 !zf-absolute !zf-right-3 !zf-top-3" @click="toggleShowPass"
                        v-if="showPass" />
                    <EyeSlashIcon class="!zf-w-6 !zf-h-6 !zf-absolute !zf-right-3 !zf-top-3 " @click="toggleShowPass"
                        v-if="!showPass" />
                </div>
                <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="formErrors.password !== ''">
                    {{ formErrors.password }}
                </p>
            </div>
            <input type="submit" value="Login" :disabled="isLoading"
                class="!zf-px-4 !zf-py-2 !zf-m-1 zf-rounded-full zf-font-bold zf-border-ziply-blue !zf-border-2 zf-text-ziply-blue hover:!zf-bg-ziply-blue hover:!zf-text-white"
                :class="{
            'zf-opacity-50 zf-cursor-not-allowed': isLoading,
        }" />
        </form>
    </div>
</template>