<script setup>
import { defineProps, ref } from 'vue'
import VueMarkdown from 'vue-markdown-render'
import MarkItExternalLink from 'markdown-it-external-link';
const plugins = [MarkItExternalLink]
const options = {
    linkify: true
}

const props = defineProps({
    message: String | Object,
    sendMessage: Function
})

const formData = ref({});
const formErrors = ref({});

let _message = { ...props.message };
try {
    let _content = _message?.content
    if (typeof _content === 'string' && _content.indexOf("attachments") !== -1) {
        _content = JSON.parse(_message?.content)
        if (_content.attachments?.length > 0 && _content.attachments[0].contentType === 'application/vnd.microsoft.card.adaptive') {
            _message.content = _content.attachments[0].content
            _message.type = 'AdaptiveCard'
        } else {
            _message.content = {
                question: _content?.text,
                options: _content?.suggestedActions?.actions ?? []
            }
            _message.type = 'MCQ'
        }
    } else if (typeof _content === 'string' && _content.indexOf("attachments") === -1) {
        _message.type = 'STRING'
    }
}
catch (err) {
    console.log(err)
}

</script>

<template>
    <div class="!zf-bg-gray-100 zf-rounded-lg !zf-px-4 !zf-py-2" v-if="_message.type === 'STRING'">
        <p class="!zf-text-gray-800 !zf-text-sm !zf-text-left zf-message">
            <vue-markdown :source="_message.content" :options="options" :plugins="plugins"></vue-markdown>
        </p>
    </div>
    <div class="!zf-bg-gray-100 !zf-w-full zf-rounded-lg !zf-px-4 !zf-py-2" v-if="_message.type === 'MCQ'">
        <p class="chetan !zf-text-gray-800 !zf-text-sm !zf-text-left !zf-w-full">
            <vue-markdown :source="_message.content.question" :options="options" :plugins="plugins"
                class="link-para"></vue-markdown>
        </p>
        <div class="!mb-3 block">
            <button v-for="item in _message.content.options" type="button" @click="sendMessage(item.value)"
                class="!zf-px-3 !zf-py-1 !zf-m-1 zf-rounded-full zf-border-ziply-blue !zf-border zf-text-ziply-blue !zf-text-sm">
                {{ item.text }}
            </button>
        </div>
    </div>
    <div class="!zf-bg-gray-100 zf-rounded-lg !zf-px-4 !zf-py-2" v-if="_message.type === 'AdaptiveCard'">
        <div v-for="item in _message.content.body " :key="item.id">
            <div v-if="item.type === 'TextBlock'">
                <p class="!zf-text-grey-800 !zf-text-sm !zf-text-left zf-message">{{ item.text }}</p>
            </div>
            <div v-else-if="item.type === 'Input.Text' || item.type === 'Input.Number'" class="zf-mt-2">
                <input class="zf-w-full !zf-p-2 zf-border-2 zf-border-gray-400 zf-rounded-lg zf-outline-none"
                    v-model="formData[item.id]" :placeholder="item.placeholder"
                    :type="item.type === 'Input.Number' ? 'number' : 'text'" :required="item.isRequired" />
                <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="formErrors[item.id]">{{
        formErrors[item.id] }}</p>
            </div>
            <div v-else-if="item.type === 'Input.ChoiceSet'" class="zf-mt-2 zf-mb-2">
                <label :for="item.label" class="!zf-flex zf-text-grey-800 ">{{ item.label }}</label>
                <select class="zf-w-full !zf-p-2 zf-border-2 zf-border-gray-400 zf-rounded-lg zf-outline-none"
                    v-model="formData[item.id]" :required="item.isRequired">
                    <option v-for=" choice  in  item.choices " :name="item.label" :key="choice.value"
                        :value="choice.value">
                        {{ choice.title }}
                    </option>
                </select>
                <p class="!zf-text-red-700 !zf-mb-3 !zf-text-sm zf-p-0" v-if="formErrors[item.id]">{{
        formErrors[item.id] }}</p>
            </div>
        </div>
        <button type="button" @click="validateFormData(formData)"
            class="!zf-px-2 !zf-py-1 !zf-mt-2 zf-rounded-full zf-border-ziply-blue !zf-border zf-text-ziply-blue !zf-text-sm">
            Submit
        </button>

    </div>
</template>
